import React, {useState, useRef} from 'react'
import {Link} from 'gatsby'

import PortableText from './portableText'
import styles from './winners.module.css'

export default function Winners ({data, awards}) {
  // console.log({awards})

  const awardList = awards
  // console.log(awardList)

  // Set State
  const [searchFirstName, setSearchFirstName] = useState('')
  const [searchLastName, setSearchLastName] = useState('')
  const [winnerData, setWinnerData] = useState(data)

  const messagesEndRef = useRef(null)

  // Form Functionss
  const handleSubmit = e => {
    e.preventDefault()

    const filteredData = data.filter(item => {
      let firstNameResults = item
      let lastNameResults = item
      // console.log({item})

      if (searchFirstName) {
        firstNameResults =
          item.node.firstName
            .toLowerCase()
            .search(searchFirstName.toLowerCase()) !== -1
      }

      if (searchLastName) {
        lastNameResults =
          item.node.lastName
            .toLowerCase()
            .search(searchLastName.toLowerCase()) !== -1
      }

      return firstNameResults && lastNameResults
    })
    setWinnerData(filteredData)
    messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
  }

  const handleLastNameChange = e => {
    setSearchLastName(e.target.value)
  }
  const handleFirstNameChange = e => {
    setSearchFirstName(e.target.value)
  }

  return (

    <div>
      {/* FILTERS */}
      <form id='specialistsForm' onSubmit={handleSubmit} className={styles.winnerForm}>

        <div className={styles.fieldContainer}>
          <label
            className=''
            htmlFor='first-name'
          >By First Name
          </label>
          <input
            onChange={handleFirstNameChange}
            className=''
            id='first-name'
            name='first-name'
            type='text'
          />
        </div>

        <div className={styles.fieldContainer}>
          <label
            className=''
            htmlFor='last-name'
          >By Last Name
          </label>
          <input
            onChange={handleLastNameChange}
            className=''
            id='last-name'
            name='last-name'
            type='text'
          />
        </div>

        <div className={styles.buttonContainer}>
          <button
            className=''
            onClick={handleSubmit}
            name='submit'
            aria-label='Submit form'
          >Search
          </button>

        </div>
      </form>

      <div aria-live='polite' ref={messagesEndRef}>
        {awardList
          .filter(award => award.node.title !== 'Outstanding Character Athletes')
          .map(award => (
            <div key={award.node._id} className={styles.awardWrapper}>

              <h3 className={styles.awardTitle}>{award.node.title}</h3>

              {award.node._rawBody && <PortableText blocks={award.node._rawBody} />}

              {winnerData
                .filter(test => test.node.awardCHAMP.title === award.node.title)
                .sort((a, b) => (a.node.lastName > b.node.lastName) ? -1 : 1)
                .sort((a, b) => (a.node.startYear > b.node.startYear) ? 1 : -1)
              // .sort((a, b) => a.node.startYear.localeCompare(b.node.startYear))
                .map(winner => (
                  <div key={winner.node._id} className={styles.winner}>
                    <Link to={`/${winner.node.slug.current}`}>
                      <span className={styles.years}>{winner.node.startYear}-{winner.node.endYear}</span>
                      <h4 className={styles.name}>{winner.node.firstName} {winner.node.lastName}</h4> {winner.node.school_name && (<h5 className={styles.school}> {winner.node.school_name.title} </h5>)}
                      <span className={styles.winnerLink}> view winner's page </span>
                    </Link>
                  </div>
                ))}
              {winnerData.filter(test => test.node.awardCHAMP.title === award.node.title).length <= 0 && (<p className={styles.error}>No matches found.</p>)}
            </div>
          ))}

      </div>
    </div>
  )
}
